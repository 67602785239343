<template>
	<van-tabbar class="tabbar-box" v-model="activeData" :active-color="'#3760FF'">
		<van-tabbar-item name="/home" @click="gopage('/distributionHomePage')">
			<svg-icon :icon-class="currentPath === '/distributionHomePage' ? 'home-tabbar-icon2' : 'home-tabbar-icon1'" class-name="home-icon tabbar-icon"></svg-icon>
			<p class="tabbar-text" :style="currentPath === '/distributionHomePage' ? 'color:#3760FF' : 'color:#333'">首页</p>
		</van-tabbar-item>
		<van-tabbar-item name="/learning" @click="gopage('/learning')">
			<svg-icon :icon-class="currentPath.includes('/learning') ? 'learning-tabbar-icon2' : 'learning-tabbar-icon1'" class-name="home-icon tabbar-icon"></svg-icon>
			<p class="tabbar-text" :style="currentPath === '/learning' ? 'color:#3760FF' : 'color:#333'">学习</p>
		</van-tabbar-item>
		<van-tabbar-item name="/saccount" @click="gopage('/saccount')">
			<svg-icon :icon-class="currentPath === '/saccount' ? 'account-tabbar-icon2' : 'account-tabbar-icon1'" class-name="account-icon tabbar-icon"></svg-icon>
			<p class="tabbar-text" :style="currentPath === '/saccount' ? 'color:#3760FF' : 'color:#333'">我的</p>
		</van-tabbar-item>
	</van-tabbar>
</template>
<script>
export default {
	name: 'jigouNav',
	data() {
		return {
			activeData: '',
		}
    },
    watch:{
        active(val){
            this.activeData = val
        }
    },
	computed: {
		currentPath() {
			return this.$route.path
		},
	},
	mounted() {},
	methods: {
		gopage(path) {
			this.$router.push(path)
		},
	},
}
</script>
<style lang="stylus" scoped>
.tabbar-icon{
    font-size: 44px;
    margin-bottom: 10px;
}
.tabbar-text{
    margin-bottom 10px
}
</style>
