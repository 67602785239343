export default (API) => ({
    // 获取管理员的租户列表
    getFindOrganList () {
        return API({ url: `/usercenter/v1/account/findOrganList`})
    },
    // 获取管理员的机构列表
    getFindAdminBranchList () {
        return API({ url: `/usercenter/v1/account/findAdminBranchList` });
	},
	//获取入驻人社子机构域名
	getSaasBranchDomain(branchId) {
		return API({
				url: `/modular/admin/v1/saasBranchDomain/${branchId}`,
			})
    },
    // 判断当前培训机构类型 0 教学资源合作机构 1 参培单位
    findTrainBranchType(params){
        return API({
            url: `/usercenter/v1/branchApplyRecord/findTrainBranchType`,
            params
		})
	},
	// oss 上传文档的凭证
    getAliyunDocConf() {
        return API({ url: '/common-services/v1/resource/getUploadDocVoucher', method:  'post' })
    },
	// 参培单位入驻
	saveJoinTrain(data) {
		return API({
			url: `/saas/v1/branchApplyEnclosure/saveJoinTrain`,
			method: 'post',
			data,
		})
	},
	// 教学资源合作机构入驻
	saveResourceCooperation(data) {
		return API({
			url: `/saas/v1/branchApplyEnclosure/saveResourceCooperation`,
			method: 'post',
			data,
		})
	},
	// 获取子机构logo、简称
	showBranchInfo(params) {
		return API({
			url: `/usercenter/v1/branch/notLoginShowBranchInfo`,
			params,
		})
	},
	// 获取职业、工种列表
	getAllLevelList() {
		return API({
			url: `/usercenter/v1/branchApplyRecord/getWorkType`,
		})
	},
	// 职业列表新接口
    getAllLevelListNew() {
		return API({
            url: `/saas/v1/renshe/vocationcode/getVocationTree`
		})
    },
	// 注册
	postAccountRegister(data) {
		return API({
			url: `/usercenter/v1/account/register2BranchForUcode`,
			method: 'post',
			data,
		})
	},
	// 获取图片链接
	uploadPicture(data){
		return API({
			url: `/resource/v1/resource/uploadPicture`,
			method: 'post',
			data,
		})
    },
    // 自动注册到机构
    accountJoinBranch(branchUcode){
        return API({ url: `/usercenter/v1/account/accountJoinBranch?branchUcode=${branchUcode}`}); 
	},
	// 下载承诺函模板
	download(){
		return API({
			url: `/saas/v1/branchApplyEnclosure/download`,
			method: 'get',
		})
	},
	// 查询当前账号是伟东参培机构还是伟东下的渠道方
	// wdBranch:是否为伟东参培机构
	// wdSubBranch：是否为伟东下的渠道方
	currBranchInfo(){
		return API({
			url:`/usercenter/v1/branch/currBranchInfo`
		});
	},
	// 是否是伟东或者平行机构
	branchIdIsWDBranch(params) {
		return API({
			url: `/cms/v1/notice/branchIdIsWDBranch`,
			method: 'get',
			params
		})
	},
})
