export default {
    state: {
        config: {},
        isWdBranch:null,//是否是伟东或者平行机构
    },

    getters: {
        examListConfig(state) {
            return state.config.blocks.find(
                block => block.name === 'exam_list',
            );
        },

        examDetailConfig(state) {
            return state.config.blocks.find(
                block => block.name === 'exam_detail',
            );
        },
    },

    mutations: {
        setConfig(state, payload) {
            state.config = payload;
        },
        setWdBranch(state, payload){
            state.isWdBranch = payload;
        }
    },

    actions: {
        setConfig({ commit, config }) {
            commit('setConfig', config);
        },
    },
};
