export default API => ({
    // 推广
    canPromote(params) {
        return API({ //简介
            url: '/saas/v1/renshe/goods/canPromote',
            params
        });
    },
     // 申请分销
    getDistribute(data) {
        return API({
            url:`/order/v1/trainOpenPlatform/distribute?distributionGoodsId=${data.distributionGoodsId}`,
            method:'get',
        });
    },
    // 点击商品列表的商品调用此接口
    distributeAndGetGoods( params ) {
        return API({ 
            method:'post',
            url: `/saas/v1/renshe/goods/distributeAndGetGoods?distributionGoodsId=${params}`,
        });
    },
   
    
})
