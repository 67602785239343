export default [
    {
        path: '/trainStandard',
        name: 'trainStandard',
        meta: {
            title: '培训项目',
            login: false,
        },
        component: () => import(/* webpackChunkName: 'trainStandard' */ './page/index.vue'),
    },
]