export default {
    state: {
        pageList: [],
        settings: [],
        showAddTeachState: false,
        showAddKnowState: false,
        teachItemInfo: {
            certificateImgs: []
        },
        knowItemInfo:{
            urlList:[]
        }
    },

    getters: {

    },

    mutations: {
        storeSetPageList(state, pageList) {
            state.pageList = pageList;
        },
        storeSetPageSettings(state, settings) {
            state.settings = settings;
        },
        getAddTeachState(state, payload){
            state.showAddTeachState = payload;
        },
        getAddKnowState(state, payload){
            state.showAddKnowState = payload;
        },
        getKnowItemInfo(state, payload){
            state.knowItemInfo = payload
        },
        getTeachItemInfo(state, payload){
            state.teachItemInfo = payload
        }
    },

    actions: {
    },
};



