export default [
  {
    path: '/home',
    name: 'RenSheHome',
    meta: {
      title: '人力资源和社会保障部社会保障能力建设中心',
      login: false,
      showTabbar: true
    },
    component: () =>
      import(/* webpackChunkName: 'RenSheHome' */ './page/index.vue'),
  },
  {
    path: '/distributionHomePage',
    name: 'distributionHomePage',
    meta: { login: false, showTabbar: true},
    component: () =>
      import(/* webpackChunkName: 'RenSheHome' */ './page/distributionHomePage.vue'),
  },
];
