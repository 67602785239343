const routerArr = [
	{
		path: '/login',
		name: 'login',
		meta: { title: '登录', login: false },
		component: () => import(/* webpackChunkName: 'shjd' */ './page/logIn.vue'),
	},
	{
		path: '/my',
		name: 'my',
		meta: { title: '我的', login: false, showTabbar: true },
		component: () => import(/* webpackChunkName: 'my' */ './page/my.vue'),
	},
	{
		path: '/registered',
		name: 'registered',
		meta: { title: '注册', login: false },
		component: () => import(/* webpackChunkName: 'registered' */ './page/registered.vue'),
	},
]
export default routerArr
