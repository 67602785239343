<template>
	<div id="wdc-app" class="wdc-app">
		<router-view class="view" :style="{ paddingBottom: showTabbar ? '60px' : '0' }" />
		<template v-if="showTabbar">
			<rensheNav :active="active" v-if="isrenshe" />
			<jigouNav :active="active" v-else />
		</template>
	</div>
</template>

<script>
import addCmsPageNav from './view/common/addCmsPageNav.vue'
import jigouNav from './view/common/jigouNav'
import rensheNav from './view/common/rensheNav'
import isrenshe from '@/utils/judgeDomain'
export default {
	data() {
		return {
            active:'',
			isrenshe: true,
			showTabbarList: ['/goodsList', '/learning' ,'/saccount'], // 页面需要显示tabber
		}
    },
    watch:{
        $route(val){
            this.active = val.path
        }
    },
	computed: {
		showTabbar() {
            let isShow1 = this.$route.meta && this.$route.meta.showTabbar
            let isShow2 = this.$route.path && this.showTabbarList.includes(this.$route.path)
			return isShow1 || isShow2
		},
	},
	components: {
		rensheNav,
		jigouNav,
	},
	created() {
		this.isrenshe = isrenshe
	},
	mounted() {},
	methods: {},
}
</script>

<style lang="stylus">
html, body, .wdc-app {
  height: 100%;
}

.view {
    box-sizing border-box
    // padding-bottom 50PX
}

.van-notify--danger {
  background-color: #ee0a24 !important;
}

.van-tabbar--fixed {
  padding-bottom: 10px;
  position: fixed !important;
  bottom: 0;
  left: 0;
}

.van-tabbar-item__text {
  text-align: center;
}

.nav-icon {
  width: 44px;
  margin-bottom:4px;
}

input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset;
  -webkit-text-fill-color: #333;
}

input[type=text]:focus, input[type=password]:focus, textarea:focus {
  -webkit-box-shadow: 0 0 0 1000px white inset;
}
</style>
