import API from './../libs/axios'

let apiArr = {}
const Routers = require.context('./modules/', true, /\.js$/)
const wdnRouters = require.context('wdn-h5/src/api/modules', true, /\.js$/)


wdnRouters.keys().forEach(key => { 
  let urlArr = key.split("/");
  let apiKey=urlArr[urlArr.length-1].split(".")[0]
  apiArr[apiKey] = {
    ...apiArr,
    ...wdnRouters(key).default(API)
  }
})

Routers.keys().forEach(key => {
  let urlArr = key.split("/");
  let apiKey=urlArr[urlArr.length-1].split(".")[0]
  apiArr[apiKey] = {
    ...apiArr,
    ...Routers(key).default(API)
  }

})

export default {
  ...apiArr
}
