import URL from '../../config/url'
export default API => ({
    findIndexTrain({data,params}) {
        return API({ //简介
            url: '/train/v1/train/findIndexTrain',
            data,
            params
        });
    },
    /**
     * 获取配置项接口
     */
    configurations() {
      return API({ //简介
          url: `/modular/admin/v1/configurations/${URL.confUrl}`,
      });
    },
    getWorkUrl() {
        return API({ 
            method:'get',
            url: `/dynamic/v1/maas/getWorkUrl`,
        });
    },
    // 首页滚动下单信息
    findOrderUser( branchId ) {
        return API({ 
            method:'get',
            url: `/saas/v1/order/findOrderUser?branchId=${branchId}`,
        });
    },
    // 首页商品列表
    distributionGoodsList( data, params ) {
        return API({ 
            method:'post',
            url: `/saas/v1/renshe/goods/distributionGoodsList?pageNum=${params.pageNum}&pageSize=${params.pageSize}`,
            data
        });
    },
    // 首页筛选信息
    filterInfo( params ) {
        return API({ 
            method:'get',
            url: `/saas/v1/renshe/goods/filterInfo?branchId=${params.branchId}&centerType=${params.centerType}&tagFlag=${params.tagFlag}`,
        });
    },
    
})
