import Vue from 'vue';
import Router from 'vue-router';
import md5 from 'js-md5'
import Axios from 'axios'
import api from './../api'
import routes from './routers';
import store from '../store/index';
import config from '../config/url';
import { Notify,Toast } from 'vant'
import isrenshe from '@/utils/judgeDomain'

Vue.use(Router);
const router = new Router({
    mode: 'history',
    routes,
});


let frontendConfig = null
const breadArr = []

    router.beforeEach(async (to, from, next) => {
        console.log("-------------to.path-----------",to.path)
        await getIsWdBranchId();
        // 记录触发实名认证的页面
        if (to.name === 'idcard' || to.name === 'cepverification' || to.name === 'goodsList' || to.name === 'goodsDetail') {
            store.state.idcardBefore = from.fullPath
        }
        // 携带手机号则登录后跳转实名认证页面
        if (to.query.phone) {
            sessionStorage.setItem('realName', true)
        }
        // 分销商id登录接口用
        if (to.query.distributionId) store.state.distributionId = to.query.distributionId
        let {token,organ,actor,branch,isAutoRegister,fromUrl} = to.query
        // 如果通过c平台跳转过来
        if(fromUrl === 'c_platform'){
            sessionStorage.setItem('fromUrl','c_platform')
        }
        // 如果携带token自动处理后续登录逻辑(isAutoRegister没有账号是否自动注册到机构)
        if(token){
            // 保证登录最新的账号
            store.commit('logout')
            localStorage.setItem('token',token)
            // 切换机构逻辑
            if(branch){
                localStorage.setItem('organ',organ)
                localStorage.setItem('actor',actor)
                localStorage.setItem('branch',branch)
                // 获取用户信息
                getUserInfo()
                // 获取用户id
                findUserId()
            }else{
                await afterLogin(isAutoRegister)
            }
        }else{
            token = localStorage.getItem('token') || ''
        }
        if(frontendConfig && token){
            if (localStorage.getItem('pageTitle')) {
                document.title = localStorage.getItem('pageTitle');
            }
        }
        if (from.name === 'projectIntro' && to.name === 'goodsDetail') {
            localStorage.removeItem('rehsheBread')
    
            let obj = {
                path: from.path,
                query: from.query,
                nameV: '项目介绍',
            }
            breadArr[0] = obj
            localStorage.setItem('rehsheBread', JSON.stringify(breadArr))
        }
    
        if (from.name === 'distributionHomePage' && to.name === 'goodsDetail') {
            localStorage.removeItem('rehsheBread')
            let obj = {
                path: from.path,
                query: from.query,
                nameV: '首页',
            }
            breadArr[0] = obj
            localStorage.setItem('rehsheBread', JSON.stringify(breadArr))
        }
        if (token && to.path === '/login') {
            if (to.query.name) { // 跳转机构学员端商品详情（机构学员端登录状态）
                next({ path:to.query.name + window.location.search,replace:true })
            } else {
                //避免重复登录
                next({ path:'/',replace:true })
            }
        }
        
        if (!token && to.meta.login) {
            next({
                path: '/login',
                query: {
                    name: to.name,
                },
            })
        } else if (to.path === '/') {
            let homePage = isrenshe ? '/home' : '/distributionHomePage'
            next(homePage)
        } else {
            next()
        }
    });

router.afterEach(() => {
    window.scrollTo(0, 0);
});

// 是否是伟东/平行机构
async function  getIsWdBranchId(){
	if(store.state.app.isWdBranch===true || store.state.app.isWdBranch===false){
		return
    }
    if(localStorage.getItem('branchId')=='' || localStorage.getItem('branchId')==null){
        return
    }
    await api.rensheUsercenter.branchIdIsWDBranch({branchId:localStorage.getItem('branchId')}).then(res=>{
		if(res.code==0){
			store.commit('setWdBranch',res.data)
		}
	})
}
// 登录后续逻辑
async function afterLogin(isAutoRegister) {
    Toast.loading({
        duration: 0,
        message: '加载中...',
        forbidClick: true,
        loadingType: 'spinner',
    })

    let branchId = localStorage.getItem('branchId')
    let branchUcode = localStorage.getItem('branchUcode')
    if(!branchId || !branchUcode){
        let res = await api.configuration.getConfigurationNew()
        branchId = res.data.branchId
        branchUcode = res.data.branchUcode
        localStorage.setItem('branchId',branchId)
    }
    // 获取机构信息
    return Axios({
        baseURL:config.API,
        url:'/usercenter/v1/account/findUserBranchList',
        headers:{ token: localStorage.getItem('token') }
    }).then(res => {
        const branchRes = res.data
        if(branchRes.success){
            if (branchRes.data && branchRes.data.length) {
                let currentBranch = null
                currentBranch = branchRes.data.find((item) => {
                    let branch = md5(branchId + item.token).toUpperCase()
                    return item.branch === branch
                })
                if(currentBranch){
                    localStorage.setItem('code', currentBranch.code)
                    localStorage.setItem('organ', currentBranch.organ)
                    localStorage.setItem('actor', currentBranch.actor)
                    localStorage.setItem('branch', currentBranch.branch)
                    currentBranch.channel && localStorage.setItem('channel', currentBranch.channel)
                    // 获取用户信息
                    getUserInfo()
                    // 获取用户id
                    findUserId()
                    Toast.clear()
                }else{
                    if(isAutoRegister){
                        return accountJoinBranch(branchUcode)
                    }else{
                        Toast.clear()
                        Notify({ type: 'danger', message: '暂未加入当前机构' })
                    }
                }
            }else{
                if(isAutoRegister){
                    return accountJoinBranch(branchUcode)
                }
            }
        }else if(branchRes.code === 303){
            if(isAutoRegister){
                return accountJoinBranch(branchUcode)
            }else{
                Toast.clear()
                Notify({
                    message: branchRes.message,
                    type: 'danger',
                    duration: 2000
                })
            }
        }else {
            Toast.clear()
            Notify({
                message: branchRes.message,
                type: 'danger',
                duration: 2000
            })
        }
    }).catch(error => {
        Toast.clear()
        console.log(error,'出错了-------------------')
    })
}

// 获取机构列表
async function getFindUserBranchList() {
    const branchId = localStorage.getItem('branchId')
    // 获取机构信息
    let branchRes = await api.account.getFindUserBranchList({})
    Toast.clear()
    if (branchRes.data && branchRes.data.length) {
        let currentBranch = null
        currentBranch = branchRes.data.find((item) => {
            let branch = md5(branchId + item.token).toUpperCase()
            return item.branch === branch
        })
        if(currentBranch){
            localStorage.setItem('code', currentBranch.code)
            localStorage.setItem('organ', currentBranch.organ)
            localStorage.setItem('actor', currentBranch.actor)
            localStorage.setItem('branch', currentBranch.branch)
            currentBranch.channel && localStorage.setItem('channel', currentBranch.channel)
        }else{
            Notify({ type: 'danger', message: '暂未加入当前机构' })
            return
        }
    }else{
        return 
    }
    // 获取用户信息
    getUserInfo()
    // 获取用户id
    findUserId()
}

// 自动注册到跟机构
async function accountJoinBranch(branchUcode){
    return api.rensheUsercenter.accountJoinBranch(branchUcode).then(res => {
        if(res.success){
            return getFindUserBranchList()
        }else{
            Toast.clear()
        }
    })
}

// 获取用户信息
function getUserInfo() {
	api.usercenter.getAccountUserInfo({}).then((res) => {
		localStorage.setItem('WDuserInfo', JSON.stringify(res.data))
		store.commit('setUserInfo', res.data)
	})
}

// 查询用户id
function findUserId() {
	api.usercenter.findUser().then((res) => {
		if (res.code == 0) {
			let subUser = { id: res.data.id }
			localStorage.setItem('subUserInfo', JSON.stringify(subUser))
		}
	})
}
export default router;
