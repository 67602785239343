export default [
    {
        path: '/projectIntro',
        name: 'projectIntro',
        meta: {
            title: '培训项目',
            login: false,
        },
        component: () => import(/* webpackChunkName: 'projectIntro' */ './page/index.vue'),
    },
]