/**
 * @file
 * @author tanghao
 * @date 2019-06-19
 */

import Vue from 'vue';
import Vuex from 'vuex';
import app from './module/app';
import pages from './module/pages';
import api from './../api'
//引入
import dynamic from '@wd/lowcode-h5/plugin/store/index.js';
Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        //
        tabbar: {
            show: true,
            active: 'home',
        },
        pageConfigs: [],
        facecount:0,
        findBindInfo: null,
        distributionId: null,
        idcardBefore: null,
        userInfo: localStorage.getItem('WDuserInfo') && localStorage.getItem('WDuserInfo') != 'undefined'? JSON.parse(localStorage.getItem('WDuserInfo')) : {},
        surpluscount:4,
        isOpen:false,
        branchId:'',
        branchUcode:'',
        homepage:''
    },
    mutations: {
        setUserInfo(state, payload) {
            state.userInfo = {...state.userInfo,...payload};
            //在线客服-客户信息扩展字段
            var WDuserInfo = JSON.parse(localStorage.getItem('WDuserInfo'));
            var customField = {};
            if(WDuserInfo){
                customField = {
                    "姓名":localStorage.getItem('WDuserInfo') ?  WDuserInfo.name : '',
                    "手机号":localStorage.getItem('WDuserInfo') ? WDuserInfo.phone :'',
                    "邮箱":localStorage.getItem('WDuserInfo') ? WDuserInfo.mail : '',
                    "所在机构":localStorage.getItem('WDuserInfo') ? WDuserInfo.branchName :'',
                };
                window.qimoClientId = {
                    nickName:localStorage.getItem('WDuserInfo') ?  WDuserInfo.name :'',
                    userId : localStorage.getItem('WDuserInfo').accountId,
                    customField: JSON.stringify(customField)
                };
            }
        },
        setTabbar(state, payload) {
            state.tabbar = { ...state.tabbar, ...payload };
          },
        setPageConfigs(state, payload) {
            state.pageConfigs = payload;
        },
        postAccountLoginByAuthCode (state, data) {
            api.usercenter.postAccountLoginByAuthCode({ data }).then(res => {
                state.userInfo = res
            })
        },
        setIsOpen(state, payload) {
            state.isOpen = payload;
        },
          // 机构级别
        savePersonalUserInfo(state, payload) {
            state.personalUserInfo = payload;
        },
        homepage(state,homepage){
            state.homepage = homepage;
        },
        setBranchId(state,payload){
            state.branchId = payload
        },
        setBranchUcode(state,payload){
            state.branchUcode = payload
        },
        logout(state){
            state.userInfo = {}
            // 和域名相关的一些字段需要保留
            const saasId = localStorage.getItem('saasId')
            localStorage.clear()
            sessionStorage.clear()
            localStorage.setItem('saasId', saasId)
        }
    },
    actions: {
        //
    },
    getters: {

        userInfo (state) {
            return state.userInfo
        }
    },
    modules: {
        app,
        pages,
        dynamic
    },
});

export default store
