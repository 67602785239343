export default [
    {
        path: '/institutionsIn',
        name: 'institutionsIn',
        meta: {
            title: '合作机构入驻',
            login: false,
            showTabbar:true
        },
        component: () => import(/* webpackChunkName: 'institutionsIn' */ './page/institutionsIn.vue'),
    },
    {
        path: '/resourceInstitutionsIn',
        name: 'resourceInstitutionsIn',
        meta: {
            title: '教学资源合作机构入驻',
            login: false,
        },
        component: () => import(/* webpackChunkName: 'resourceInstitutionsIn' */ './page/resourceInstitutionsIn.vue'),
    },
    {
        path: '/trainInstitutionsIn',
        name: 'trainInstitutionsIn',
        meta: {
            title: '参培机构入驻',
            login: false,
        },
        component: () => import(/* webpackChunkName: 'trainInstitutionsIn' */ './page/trainInstitutionsIn.vue'),
    },
]