const routerArr = [
    {
        path: '/goodsDetail',
        name: 'goodsDetail',
        meta: { title:'课程详情',login:false },
        component: () => import(/* webpackChunkName: 'courseGoods' */ './page/courseGoodsDetail.vue'),
    },
    // {
    //   path: '/gongxin_payment',
    //   name: 'payment',
    //   meta: { title:'支付',login:false },
    //   component: () => import(/* webpackChunkName: 'payment' */ './page/payment.vue'),
    // },
  ]
  export default routerArr