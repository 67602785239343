import Vue from 'vue';
import Vant from 'vant';
import { Toast } from 'vant';
import {Dialog, Notify} from 'vant'
import 'babel-polyfill';
import 'vant/lib/index.css';
import Videojs from 'video.js';
import 'video.js/dist/video-js.css'
import App from './app.vue';
import router from './router';
import store from './store';
import config from './config';
import api from './api';
import Templates from './libs/template';
import Tools from '@stroll/tools';
import { textTip } from './utils/tip'
import sensors from 'wdn-h5/src/utils/sensors'
import './libs/rem';
import * as echarts from "echarts";
import 'wdn-h5/src/libs/saas.js'
import './styl/app.styl';
import NoSleep from "nosleep.js/dist/NoSleep.min.js";
import publicFn from 'wdn-h5/src/utils/publicFn';
import API from "./libs/axios";
import URL from './config/url.js';
import './assets/icons';
import isrenshe from '@/utils/judgeDomain'

import VideoPlayer from 'vue-video-player'
import SDK from '@wd/lowcode-h5'
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
Vue.use(VideoPlayer)
import question from '@wd/question';
import '@wd/question/lib/question.css';

//引入
//解决和vue-video-player的暂停按钮的冲突问题，修改needsClick方法
import FastClick from './libs/fastclick'


//FastClick的ios点击穿透解决方案
FastClick.prototype.focus = function (targetElement) {
    let length;
    if (targetElement.setSelectionRange && targetElement.type.indexOf('date') !== 0 && targetElement.type !== 'time' && targetElement.type !== 'month') {
        length = targetElement.value.length;
        targetElement.focus();
        targetElement.setSelectionRange(length, length);
    } else {
        targetElement.focus();
    }
};
//初始化FastClick实例。在页面的DOM文档加载完成后
FastClick.attach(document.body)


Vue.use(question)

Vue.prototype.$sensors = sensors
Vue.prototype.$video = Videojs;
Vue.prototype.$NoSleep = NoSleep;
Vue.prototype.$textTip = textTip
Vue.prototype.$publicFn = publicFn;

// const ua = navigator.userAgent
// if (ua.match(/Android/i) !== null || ua.match(/iPad|iPod|iPhone/i) !== null) {
//    var src = '//cdn.jsdelivr.net/npm/eruda';
//    document.write('<scr' + 'ipt src="' + src + '"></scr' + 'ipt>');
//    document.write('<scr' + 'ipt>eruda.init();</scr' + 'ipt>');
// }




config.productionTip = process.env.NODE_ENV === 'development';

/**
 * @description 全局注册应用配置
 */
Vue.use(Vant);
Vue.use(Toast);
Vue.use(Dialog)
Vue.use(Notify)
Vue.use(Templates)

Vue.prototype.$echarts = echarts;
Vue.prototype.$config = config;
Vue.prototype.$tools = Tools
Vue.prototype.$api = api;
Vue.prototype.$eventBus = new Vue();
Vue.prototype.$sc = sensors

function getLayoutSetting() {
    API({
        url: `/modular/admin/v1/configurations/${URL.confUrl}`,
        method: 'Get',
    }).then((data) => {
            localStorage.setItem('saasId', data.data.solutionInfo?.rid)
            localStorage.setItem('branchId', data.data.branchId || '')
            localStorage.setItem('branchUcode', data.data.branchUcode || '')
            store.commit('frontendConfig', data.data.frontendConfig);
            // 配置首页 存储
            // let homepage = data.data.frontendConfig.filter(item => {
            //     if (item.key === 'homepage') {
            //         return true;
            //     }
            // })
            // store.commit('homepage', homepage);
            let homepage = [{
                value:'distributionHomePage'//机构学员端首页
            }]
            if(isrenshe){//人社平台
                homepage[0].value = 'home'
            }
            store.commit('homepage', homepage)

            sessionStorage.setItem('theme',data.data.theme)
            sessionStorage.setItem('frontendConfig',JSON.stringify(data.data.frontendConfig))
            sessionStorage.setItem('branchId', data.data.branchId || '')
            sessionStorage.setItem('branchUcode', data.data.branchUcode)
            store.commit('setBranchId', data.data.branchId || '')
            store.commit('setBranchUcode', data.data.branchUcode)
        })
        
}

//将自定义页面加入到路由中 路由必须是历史模式
Promise.all([SDK.setLayoutToRouter(router,store)]).then(() => {
    getLayoutSetting()
    new Vue({
     router,
     store,
     render: h => h(App),
   }).$mount('#wdc-app')
   }).catch(res=>{
    console.log()
    new Vue({
        router,
        store,
        render: h => h(App),
    }).$mount('#wdc-app');
});




